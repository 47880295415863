import Seq from '../helpers/Seq'
import UserService from '../api/Users/Service'

let waitingCurrentUser = []
let waitingOnboardingUrl = []
let currentUser
let onboardingUrl

const getCurrentUser = function (cb) {
  UserService.currentUser().then(res => {
    currentUser = res
    cb()
  })
}

const updateOnboardingUrl = function (cb) {
  UserService.fetchOnboardingUrl()
    .then(res => {
      onboardingUrl = res
      cb()
    })
    .catch(() =>
      toastr.error('There was a problem fetching the onboarding url', {
        timeOut: 10000,
      })
    )
}

export default {
  getCurrentUser: function (callback) {
    waitingCurrentUser.push(callback)
    if (waitingCurrentUser.length > 1) {
      return
    }
    const seq = new Seq()
    if (undefined == currentUser) {
      seq.push(function () {
        getCurrentUser(seq.next)
      })
    }
    seq.push(function () {
      waitingCurrentUser.forEach(function (cb) {
        cb(currentUser)
      })
      waitingCurrentUser.splice(0)
    })
    seq.start()
  },
  getOnboardingUrl: function (callback) {
    waitingOnboardingUrl.push(callback)
    if (waitingOnboardingUrl.length > 1) {
      return
    }
    const seq = new Seq()
    if (undefined == onboardingUrl) {
      seq.push(function () {
        updateOnboardingUrl(seq.next)
      })
    }
    seq.push(function () {
      waitingOnboardingUrl.forEach(function (cb) {
        cb(onboardingUrl)
      })
      waitingOnboardingUrl.splice(0)
    })
    seq.start()
  },
}
