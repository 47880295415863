export default function Seq(pTitle, pDebug) {
  var iSubStep = 0,
    lSubSteps = [],
    lDbg = pDebug
      ? function (_pWhat) {
          console.log('[seq_' + pTitle + ']: ' + _pWhat)
          return
        }
      : function () {
          return
        }
  lDbg('created')
  this.next = function () {
    iSubStep++
    lDbg('next step ' + iSubStep)
    if (iSubStep < lSubSteps.length) {
      lSubSteps[iSubStep]()
    }
  }
  this.push = function (pSubStep) {
    lDbg('added step ' + lSubSteps.length)
    lSubSteps.push(pSubStep)
  }
  this.insert = function (pSubStep) {
    lDbg('inserting step ' + lSubSteps.length)
    lSubSteps.splice(iSubStep + 1, 0, pSubStep)
  }
  this.start = function () {
    iSubStep = 0
    lDbg('starting')
    lSubSteps[iSubStep]()
  }
  this.curstep = function () {
    return iSubStep
  }
}
